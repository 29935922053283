<template>
  <div class="container">
    <div class="auth_login_form">
      <div class="log-card mb-4">
        <div class="dash_card_body ">

          <div v-if="!emailSent">
            <form @submit.prevent="resetPassword">
              <div v-if="resetError ">
                <div class="alert alert-danger">
                  <ul>
                    <li v-for="(err_msg,errindex) in errorMsg" :key="errindex">{{err_msg}}</li>
                  </ul>
                </div>
              </div>
              <div class="form-group">
                <label>Email:</label>
                <div>
                  <input type="text"  v-model="email"  @blur="v$.email.$touch" class="form-control" />
                </div>
                <div class="input_error" v-if="v$.email.$error">
                  <span v-if="v$.email.required.$invalid">Email is required</span>
                  <span v-if="v$.email.email.$invalid">Email is invalid</span>
                </div>
              </div>
              <div class="d-flex justify--center mt-3">
                <va-button @click="resetPassword" class="btn btn-large btn-primary my-0"><span v-if="loading" v-html="spinner"></span> <span v-else> Reset Password </span></va-button>
              </div>
            </form>
          </div>
          <div v-else>
            <div class="alert alert-success">
              {{message}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import useVuelidate from '@vuelidate/core';
import { required,email } from '@vuelidate/validators';
export default {
  name: 'recover-password',
  setup() {
      return { v$: useVuelidate() }
  },
  data () {
    return {
      email: '',
      emailSent: false,
      emailErrors: [],
      errorMsg: false,
      message: '',
      loading:false,
      resetError:false,
      spinner: "<i class='fa fa-spinner fa-spin '></i>"
    }
  },
  validations() {
        return {
            email: { required ,email},
        }
  },
  
  methods: {
   async resetPassword(){
      this.loading = true;
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) {
          this.loading = false;
          return
      }
      const formData = new FormData();
      const baseurl = window.location.origin +"/";
      formData.append('email', this.email);
      formData.append('base_url', baseurl);
      const url = this.dashBaseUrl + "/auth/send-resetEmail";
      this.axios.post(url, formData,).then((response) => {
              if(response.status == 200){
                this.emailSent = true;
                this.loading = false;
                this.message = response.data.message
                this.resetError = false;
              }
              
          })
          .catch((error) => {
              console.log('error',error);
              this.loading = false;
              if(error.response.status === 400){
                this.resetError = true;
                this.errorMsg = error.response.data.errors;
              }
          });
    }
  },
   mixins: [ReusableDataMixins]
}
</script>

<style lang="scss">
</style>
